.nav-container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9001;
}

.nav {
  margin: 0 auto;
  width: 90%;
  max-width: 30em;

  &__link {
    padding: 0.25em;
    display: inline-block;
    position: relative;
    color: $color-inverted;
    background-color: $color-nav;
    font-family: $font-body-copy-alt;
    box-shadow: none;

    &::before {
      content: '';
      background-color: $color-nav;
      border-radius: 0.5em;
      position: absolute;
      top: 0;
      right: 0;
      bottom: -0.25em;
      left: 0;
      z-index: -1;
      transform: perspective(0.5em) rotateX(-5deg);
      transform-origin: bottom;
    }
  }
}

@media screen and (min-width: $bp-tablet-landscape) {
  .nav {
    margin-left: 5vw;
  }
}

@media (prefers-color-scheme: dark) {
  .nav {
    &__link {
      color: $color-body-copy-dark;
      background-color: $color-nav-dark;

      &::before {
        content: '';
        background-color: $color-nav-dark;
      }
    }
  }
}
