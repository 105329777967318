@keyframes bouncing-headphones {
  0%,
  40% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.5%);
  }

  60% {
    transform: translateY(-0.5%);
  }

  70% {
    transform: translateY(0);
  }
}

@keyframes turn-head {
  0%,
  40% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  60% {
    transform: rotate(0deg);
  }
}

@keyframes angel-hover {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-1em);
  }
}

@keyframes angel-shadow {
  0%,
  100% {
    transform: translate(-50%, 0) scale(1);
  }

  50% {
    transform: translate(-50%, 0.5em) scale(0.7);
  }
}

@keyframes wing-left {
  0%,
  100% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

@keyframes wing-right {
  0%,
  100% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

.sybren {
  width: 200px;
  height: 266px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 10;
  flex-shrink: 0;

  .inline-syb & {
    display: inline-block;
  }

  .contains-syb & {
    margin: 0;
  }

  img {
    width: 100%;
    border: 0;
  }
}

@media screen and (min-width: $viewport-syb-min-width) and (min-height: $viewport-syb-min-height) {
  .sybren {
    width: 400px;
    height: 532px;
  }
}

.sybren .lazy-load {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sybs-body {
  position: absolute;
  top: 0;
}

.sybs-head {
  position: absolute;
  z-index: 40;
  transform-origin: 50% 33%;

  &.animated {
    animation: turn-head 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) 5s infinite;
  }
}

.animated-head {
  animation: turn-head 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) 5s infinite;
}

.sybs-headphones {
  position: absolute;
  z-index: 30;
  animation: bouncing-headphones 0.5s ease-in-out 1s infinite;
}

.hat {
  z-index: 42;
}

.sybs-wings {
  top: 0.5em;
  position: absolute;
  transition: transform 0.5s;
  animation: wing-left 1s infinite;
}

.sybs-wings + .sybs-wings {
  animation: wing-right 1s infinite;
}

.is-angel {
  .angel-container {
    position: relative;
    height: 100%;
    transform: translateY(0);
    transition: transform 1s ease-out;
    animation: angel-hover 2s infinite ease-in-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 1em;
    left: 50%;
    height: 0.5em;
    width: 7em;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translate(-50%, 0);
    transition: transform 1s;
    animation: angel-shadow 2s infinite ease-in-out;
  }

  &:hover .angel-container {
    transform: translateY(-1em);
    transition: transform 1s;
  }

  &:hover::after {
    transform: translate(-50%, 0.5em) scale(0.7);
    transition: transform 1s;
  }

  &:hover .sybs-wings {
    animation-duration: 0.5s;
  }
}
