$font-body-copy-alt: 'Whitney SSm A', 'Whitney SSm B', helvetica, arial,
  sans-serif;
$font-body-copy: 'Sentinel SSm A', 'Sentinel SSm B', Georgia, serif;

@font-face {
  font-family: 'SourceCodePro';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/SourceCodePro-Regular.woff2') format('woff2'),
    url('../fonts/SourceCodePro-Regular.woff') format('woff');
}

body {
  background: $color-background;
  color: $color-body-copy;
  font-family: $font-body-copy;
  font-size-adjust: 0.5;

  // letter-spacing: 0.085em;

  .wf-loaded & {
    font-size-adjust: none;
    letter-spacing: normal;
  }
}

a {
  color: $color-accent;
  box-shadow: inset 0 -1px;
  text-decoration: none;
}

h1,
h2,
h3 {
  text-rendering: optimizeLegibility;
}

h1 {
  margin: 0;
  font-weight: normal;
  font-size: 2em;
  line-height: 1.2;
}

h2 {
  font-weight: 400;
  margin-bottom: 0.25em;
  font-family: $font-body-copy-alt;
}

h3 {
  font-weight: 400;
  margin-bottom: 0;
  font-family: $font-body-copy-alt;
}

section {
  margin-top: 2.5em;
}

p {
  margin: 0;
  line-height: 1.4;

  h1 + & {
    margin-top: 1em;
    color: $color-low-contrast;
    font-family: $font-body-copy-alt;

    @media (prefers-color-scheme: dark) {
      color: $color-low-contrast-dark;
    }
  }

  + p {
    text-indent: 2em;
  }

  @media screen and (min-width: $bp-tablet-portrait) {
    + .figure-half-alt + p {
      text-indent: 2em;
    }
  }
}

li {
  line-height: 1.4;
}

sup {
  line-height: 1; // stop pushing the line-height of parent
  font-size: small;
}

code,
pre {
  font-family: 'SourceCodePro', monospace;
}

.var-text {
  @media screen and (min-width: $bp-tablet-landscape) {
    span {
      display: none;
    }

    &::before {
      content: attr(data-text-bp-64);
    }
  }
}

.introduction {
  margin-bottom: 1.5em;
  line-height: 1.2;
  font-size: 1.5em;

  @media screen and (min-width: $bp-tablet-landscape) {
    margin-left: -5em;
  }
}

.citation {
  margin: 1em 0;
  padding: 0.5em 2em;
  position: relative;
  font-family: $font-body-copy-alt;

  p {
    font-size: 1.2em;
  }

  cite {
    font-style: normal;
    color: $color-low-contrast;
    font-size: 0.75em;
    line-height: 1.2;

    @media (prefers-color-scheme: dark) {
      color: $color-low-contrast-dark;
    }
  }
}

@media screen and (min-width: $bp-tablet-landscape) {
  figure.code {
    margin: 2em -9em;
  }
}

@media screen and (min-width: $bp-tablet-narrow) and (max-width: $bp-tablet-portrait) {
  body {
    font-size: calc(16px + (20 - 16) * (100vw - 640px) / (768 - 640));
  }
}

@media screen and (min-width: $bp-tablet-portrait) {
  body {
    font-size: 125%;
  }
}

.thanks-olaf {
  margin-left: 1.5em;
  position: relative;
}

del,
ins {
  font-size: 0.825em;
  opacity: 0.25;
  display: inline-block;
}

ins {
  &::before {
    content: '[';
  }

  &::after {
    content: ']';
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background: $color-background-dark;
    color: $color-body-copy-dark;
  }

  a {
    color: $color-accent-dark;
  }
}
