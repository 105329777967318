/* stylelint-disable no-descending-specificity */

figure {
  margin: 0;
  padding: 2em;
  text-align: center;

  section &:first-child {
    padding-top: 0;
  }

  > img {
    max-width: 100%;
    max-height: 100vh;
  }

  figcaption {
    text-align: left;
    font-size: 0.75em;
    line-height: 1.2;
    color: $color-low-contrast;
    font-family: $font-body-copy-alt;

    @media (prefers-color-scheme: dark) {
      color: $color-low-contrast-dark;
    }
  }
}

.figure-code {
  padding: 2em 0;

  pre {
    font-size: 0.875em;
    text-align: left;
    white-space: pre-wrap;

    @media screen and (min-width: $bp-tablet-portrait) {
      font-size: 0.9em;
    }
  }

  &.figure-code--compact {
    margin: 0;
  }
}

.scale-100 {
  width: 100%;
}

.add-background {
  background: $color-white;
  padding: 1em;
}

.figure-3,
.figure-6 {
  figcaption {
    padding: 0 0.5em;
    text-align: center;
  }
}

.figure-6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  figure {
    padding: 0 0 1em;
    max-width: 50%;
  }
}

@media screen and (min-width: $bp-phone-wide) {
  img.retina-320 {
    max-width: 320px;
  }

  img.retina-480 {
    max-width: 480px;
  }
}

@media screen and (min-width: $bp-tablet-narrow) {
  .figure-wide,
  .figure-code {
    margin: 0 -2.5em;
  }
  figure {
    .figure-6 & {
      max-width: 33.333%;
    }
  }
}

@media screen and (min-width: $bp-tablet-m) {
  .figure-wide,
  .figure-code {
    margin: 0 -5em;
  }
}

@media screen and (min-width: $bp-tablet-landscape) {
  .figure-2,
  .figure-3,
  .figure-6 {
    text-align: center;
  }

  figure {
    .figure-2 &,
    .figure-3 & {
      margin-right: -4px;
      padding: 1em;
      width: 50%;
      display: inline-block;
      vertical-align: bottom;
    }

    .figure-3 & {
      width: 33%;
    }

    .figure-6 & {
      max-width: none;
      padding: 0;
    }
  }

  .figure-3,
  .figure-6 {
    display: flex;
  }

  .figure-contains-2 img {
    margin: 1em;
    display: inline-block;
    vertical-align: bottom;
  }

  .figure-wide,
  .figure-code {
    margin: 0 -10em;
  }

  .figure-half {
    margin: 0 0 0 -10em;
    max-width: 60%;
    float: left;
  }

  .figure-half-alt {
    margin: 0 -10em 0 0;
    max-width: 60%;
    float: right;
  }

  .figure-6 {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: $bp-laptop-mdpi) {
  .figure-wide.figure-6 {
    margin: 0 -15em;
  }
}
@media screen and (min-width: $bp-laptop-hidpi) {
  .figure-wide.figure-6 {
    margin: 0 -20em;
  }
}

@media (prefers-color-scheme: dark) {
  img[src$='.svg'][src*='img/articles'] {
    filter: invert(1) hue-rotate(180deg);
  }
}
