.article-list {
  margin: 0 auto;
  padding: 0;
  max-width: 30em;
  list-style: none;

  @media screen and (min-width: $bp-phone-wide) {
    margin: 1em auto;
  }

  li + li {
    border-top: 1px solid $color-line;

    @media (prefers-color-scheme: dark) {
      border-top-color: $color-nav-dark;
    }
  }

  a {
    padding: 0.5em 0.25em;
    display: block;
    position: relative;
    box-shadow: none;
    transition: transform 200ms ease-in-out;

    &::before {
      content: '';
      position: absolute;
      display: block;
      border-radius: 2px;
      width: 0.4em;
      height: 0.4em;
      left: -0.5em;
      top: 1em;
      background: currentColor;
      transform: scale(0);
      transition: transform 200ms 50ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

    &:hover {
      transform: translateX(0.5em);

      &::before {
        transform: scale(1);
      }
    }
  }

  time {
    display: block;
    color: $color-low-contrast;
    font-family: $font-body-copy-alt;
    font-size: 0.75em;

    @media (prefers-color-scheme: dark) {
      color: $color-low-contrast-dark;
    }
  }
}
