@import '../../../node_modules/normalize.css/normalize.css';
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.wrapper {
  display: table;
  width: 100%;
  height: 100%;
  margin: auto;
}
@media screen and (min-width: 40em) {
  .wrapper.index {
    display: flex;
    flex-direction: row;
  }
}

.cell {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  position: relative;
}

.page {
  max-width: 30em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.page-part {
  padding: 5vw;
}
@media screen and (min-width: 40em) {
  .page-part {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
  }
}

.article,
.page-part + .page-part {
  background-color: #f6f2f0;
  background-image: url("../img/ricepaper.jpg");
  background-image: image-set(url("../img/ricepaper.avif") type("image/avif"), url("../img/ricepaper.jpg") type("image/jpeg"));
}
@media (prefers-color-scheme: dark) {
  .article,
.page-part + .page-part {
    background-color: #262523;
    background-image: url("../img/ricepaper-dark.jpg");
    background-image: image-set(url("../img/ricepaper-dark.avif") type("image/avif"), url("../img/ricepaper-dark.jpg") type("image/jpeg"));
  }
}
@media screen and (min-width: 40em) {
  .article,
.page-part + .page-part {
    justify-content: flex-start;
    align-content: stretch;
  }
}

.article {
  padding: 5em 0;
}

.index main,
.index aside {
  display: block;
  width: 100%;
}
.index h1 {
  text-align: center;
}

.chapter {
  height: 90%;
}
@media screen and (min-width: 40em) {
  .chapter {
    max-height: 480px;
  }
}
@media screen and (min-width: 55em) and (min-height: 700px) {
  .chapter {
    max-height: 688px;
  }
}

.wrapper.chapter {
  margin-top: 2em;
  padding: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row-reverse wrap;
}
@media screen and (min-width: 25em) {
  .wrapper.chapter {
    margin-top: 0;
  }
}
@media screen and (min-width: 40em) {
  .wrapper.chapter {
    flex-wrap: nowrap;
  }
}
.wrapper.chapter .title {
  max-width: 30em;
  display: inline-block;
  vertical-align: middle;
}
.wrapper.chapter .title + div {
  display: inline-block;
  vertical-align: middle;
}

/* stylelint-disable-next-line */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .article,
.page-part + .page-part {
    background-size: 242px 242px;
  }
}
.chapter + .article .cell::before {
  content: "";
  width: 100%;
  height: 1em;
  display: block;
  position: absolute;
  top: -5em;
  background-image: linear-gradient(135deg, #fff 25%, transparent 25%), linear-gradient(225deg, #fff 25%, transparent 25%);
  background-position: 50%;
  background-size: 1em 100%;
}
@media (prefers-color-scheme: dark) {
  .chapter + .article .cell::before {
    background-image: linear-gradient(135deg, #1d1d1b 25%, transparent 25%), linear-gradient(225deg, #1d1d1b 25%, transparent 25%);
  }
}

@font-face {
  font-family: "SourceCodePro";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/SourceCodePro-Regular.woff2") format("woff2"), url("../fonts/SourceCodePro-Regular.woff") format("woff");
}
body {
  background: #fff;
  color: #333;
  font-family: "Sentinel SSm A", "Sentinel SSm B", Georgia, serif;
  font-size-adjust: 0.5;
}
.wf-loaded body {
  font-size-adjust: none;
  letter-spacing: normal;
}

a {
  color: #e34733;
  box-shadow: inset 0 -1px;
  text-decoration: none;
}

h1,
h2,
h3 {
  text-rendering: optimizeLegibility;
}

h1 {
  margin: 0;
  font-weight: normal;
  font-size: 2em;
  line-height: 1.2;
}

h2 {
  font-weight: 400;
  margin-bottom: 0.25em;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
}

h3 {
  font-weight: 400;
  margin-bottom: 0;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
}

section {
  margin-top: 2.5em;
}

p {
  margin: 0;
  line-height: 1.4;
}
h1 + p {
  margin-top: 1em;
  color: #6f6f6f;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
}
@media (prefers-color-scheme: dark) {
  h1 + p {
    color: #8d8d8d;
  }
}
p + p {
  text-indent: 2em;
}
@media screen and (min-width: 48em) {
  p + .figure-half-alt + p {
    text-indent: 2em;
  }
}

li {
  line-height: 1.4;
}

sup {
  line-height: 1;
  font-size: small;
}

code,
pre {
  font-family: "SourceCodePro", monospace;
}

@media screen and (min-width: 64em) {
  .var-text span {
    display: none;
  }
  .var-text::before {
    content: attr(data-text-bp-64);
  }
}

.introduction {
  margin-bottom: 1.5em;
  line-height: 1.2;
  font-size: 1.5em;
}
@media screen and (min-width: 64em) {
  .introduction {
    margin-left: -5em;
  }
}

.citation {
  margin: 1em 0;
  padding: 0.5em 2em;
  position: relative;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
}
.citation p {
  font-size: 1.2em;
}
.citation cite {
  font-style: normal;
  color: #6f6f6f;
  font-size: 0.75em;
  line-height: 1.2;
}
@media (prefers-color-scheme: dark) {
  .citation cite {
    color: #8d8d8d;
  }
}

@media screen and (min-width: 64em) {
  figure.code {
    margin: 2em -9em;
  }
}
@media screen and (min-width: 40em) and (max-width: 48em) {
  body {
    font-size: calc(16px + (20 - 16) * (100vw - 640px) / (768 - 640));
  }
}
@media screen and (min-width: 48em) {
  body {
    font-size: 125%;
  }
}
.thanks-olaf {
  margin-left: 1.5em;
  position: relative;
}

del,
ins {
  font-size: 0.825em;
  opacity: 0.25;
  display: inline-block;
}

ins::before {
  content: "[";
}
ins::after {
  content: "]";
}

@media (prefers-color-scheme: dark) {
  body {
    background: #1d1d1b;
    color: #c5c8c6;
  }

  a {
    color: #ea7262;
  }
}
.article-list {
  margin: 0 auto;
  padding: 0;
  max-width: 30em;
  list-style: none;
}
@media screen and (min-width: 30em) {
  .article-list {
    margin: 1em auto;
  }
}
.article-list li + li {
  border-top: 1px solid #ccc;
}
@media (prefers-color-scheme: dark) {
  .article-list li + li {
    border-top-color: #333;
  }
}
.article-list a {
  padding: 0.5em 0.25em;
  display: block;
  position: relative;
  box-shadow: none;
  transition: transform 200ms ease-in-out;
}
.article-list a::before {
  content: "";
  position: absolute;
  display: block;
  border-radius: 2px;
  width: 0.4em;
  height: 0.4em;
  left: -0.5em;
  top: 1em;
  background: currentColor;
  transform: scale(0);
  transition: transform 200ms 50ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.article-list a:hover {
  transform: translateX(0.5em);
}
.article-list a:hover::before {
  transform: scale(1);
}
.article-list time {
  display: block;
  color: #6f6f6f;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
  font-size: 0.75em;
}
@media (prefers-color-scheme: dark) {
  .article-list time {
    color: #8d8d8d;
  }
}

/* stylelint-disable no-descending-specificity */
figure {
  margin: 0;
  padding: 2em;
  text-align: center;
}
section figure:first-child {
  padding-top: 0;
}
figure > img {
  max-width: 100%;
  max-height: 100vh;
}
figure figcaption {
  text-align: left;
  font-size: 0.75em;
  line-height: 1.2;
  color: #6f6f6f;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
}
@media (prefers-color-scheme: dark) {
  figure figcaption {
    color: #8d8d8d;
  }
}

.figure-code {
  padding: 2em 0;
}
.figure-code pre {
  font-size: 0.875em;
  text-align: left;
  white-space: pre-wrap;
}
@media screen and (min-width: 48em) {
  .figure-code pre {
    font-size: 0.9em;
  }
}
.figure-code.figure-code--compact {
  margin: 0;
}

.scale-100 {
  width: 100%;
}

.add-background {
  background: #fff;
  padding: 1em;
}

.figure-3 figcaption,
.figure-6 figcaption {
  padding: 0 0.5em;
  text-align: center;
}

.figure-6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.figure-6 figure {
  padding: 0 0 1em;
  max-width: 50%;
}

@media screen and (min-width: 30em) {
  img.retina-320 {
    max-width: 320px;
  }

  img.retina-480 {
    max-width: 480px;
  }
}
@media screen and (min-width: 40em) {
  .figure-wide,
.figure-code {
    margin: 0 -2.5em;
  }

  .figure-6 figure {
    max-width: 33.333%;
  }
}
@media screen and (min-width: 55em) {
  .figure-wide,
.figure-code {
    margin: 0 -5em;
  }
}
@media screen and (min-width: 64em) {
  .figure-2,
.figure-3,
.figure-6 {
    text-align: center;
  }

  .figure-2 figure, .figure-3 figure {
    margin-right: -4px;
    padding: 1em;
    width: 50%;
    display: inline-block;
    vertical-align: bottom;
  }
  .figure-3 figure {
    width: 33%;
  }
  .figure-6 figure {
    max-width: none;
    padding: 0;
  }

  .figure-3,
.figure-6 {
    display: flex;
  }

  .figure-contains-2 img {
    margin: 1em;
    display: inline-block;
    vertical-align: bottom;
  }

  .figure-wide,
.figure-code {
    margin: 0 -10em;
  }

  .figure-half {
    margin: 0 0 0 -10em;
    max-width: 60%;
    float: left;
  }

  .figure-half-alt {
    margin: 0 -10em 0 0;
    max-width: 60%;
    float: right;
  }

  .figure-6 {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 75em) {
  .figure-wide.figure-6 {
    margin: 0 -15em;
  }
}
@media screen and (min-width: 90em) {
  .figure-wide.figure-6 {
    margin: 0 -20em;
  }
}
@media (prefers-color-scheme: dark) {
  img[src$=".svg"][src*="img/articles"] {
    filter: invert(1) hue-rotate(180deg);
  }
}
/* stylelint-disable declaration-property-value-allowed-list */
/* lioshi Theme */
/* Original theme - https://github.com/lioshi/vscode-lioshi-theme */
/* Comment */
.hljs-comment {
  color: #8d8d8d;
}

/* quote */
.hljs-quote {
  color: #b3c7d8;
}

/* Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: #cc6666;
}

/* Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-subst .hljs-link {
  color: #de935f;
}

/* Yellow */
.hljs-attribute {
  color: #f0c674;
}

/* Green */
.hljs-string,
.hljs-bullet,
.hljs-params,
.hljs-addition {
  color: #b5bd68;
}

/* Blue */
.hljs-title,
.hljs-meta,
.hljs-section {
  color: #81a2be;
}

/* Purple */
.hljs-selector-tag,
.hljs-keyword,
.hljs-function,
.hljs-class {
  color: #be94bb;
}

/* Purple light */
.hljs-symbol {
  color: #dbc4d9;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #1a1a1a;
  color: #c5c8c6;
  padding: 0.75em 1em;
  border-radius: 2px;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.nav-container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9001;
}

.nav {
  margin: 0 auto;
  width: 90%;
  max-width: 30em;
}
.nav__link {
  padding: 0.25em;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: #6f6f6f;
  font-family: "Whitney SSm A", "Whitney SSm B", helvetica, arial, sans-serif;
  box-shadow: none;
}
.nav__link::before {
  content: "";
  background-color: #6f6f6f;
  border-radius: 0.5em;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -0.25em;
  left: 0;
  z-index: -1;
  transform: perspective(0.5em) rotateX(-5deg);
  transform-origin: bottom;
}

@media screen and (min-width: 64em) {
  .nav {
    margin-left: 5vw;
  }
}
@media (prefers-color-scheme: dark) {
  .nav__link {
    color: #c5c8c6;
    background-color: #333;
  }
  .nav__link::before {
    content: "";
    background-color: #333;
  }
}
@keyframes bouncing-headphones {
  0%, 40% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5%);
  }
  60% {
    transform: translateY(-0.5%);
  }
  70% {
    transform: translateY(0);
  }
}
@keyframes turn-head {
  0%, 40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(0deg);
  }
}
@keyframes angel-hover {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1em);
  }
}
@keyframes angel-shadow {
  0%, 100% {
    transform: translate(-50%, 0) scale(1);
  }
  50% {
    transform: translate(-50%, 0.5em) scale(0.7);
  }
}
@keyframes wing-left {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}
@keyframes wing-right {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
.sybren {
  width: 200px;
  height: 266px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 10;
  flex-shrink: 0;
}
.inline-syb .sybren {
  display: inline-block;
}
.contains-syb .sybren {
  margin: 0;
}
.sybren img {
  width: 100%;
  border: 0;
}

@media screen and (min-width: 55em) and (min-height: 700px) {
  .sybren {
    width: 400px;
    height: 532px;
  }
}
.sybren .lazy-load {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sybs-body {
  position: absolute;
  top: 0;
}

.sybs-head {
  position: absolute;
  z-index: 40;
  transform-origin: 50% 33%;
}
.sybs-head.animated {
  animation: turn-head 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) 5s infinite;
}

.animated-head {
  animation: turn-head 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) 5s infinite;
}

.sybs-headphones {
  position: absolute;
  z-index: 30;
  animation: bouncing-headphones 0.5s ease-in-out 1s infinite;
}

.hat {
  z-index: 42;
}

.sybs-wings {
  top: 0.5em;
  position: absolute;
  transition: transform 0.5s;
  animation: wing-left 1s infinite;
}

.sybs-wings + .sybs-wings {
  animation: wing-right 1s infinite;
}

.is-angel .angel-container {
  position: relative;
  height: 100%;
  transform: translateY(0);
  transition: transform 1s ease-out;
  animation: angel-hover 2s infinite ease-in-out;
}
.is-angel::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 1em;
  left: 50%;
  height: 0.5em;
  width: 7em;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translate(-50%, 0);
  transition: transform 1s;
  animation: angel-shadow 2s infinite ease-in-out;
}
.is-angel:hover .angel-container {
  transform: translateY(-1em);
  transition: transform 1s;
}
.is-angel:hover::after {
  transform: translate(-50%, 0.5em) scale(0.7);
  transition: transform 1s;
}
.is-angel:hover .sybs-wings {
  animation-duration: 0.5s;
}