$bp-phone: 20em; //  320px
$bp-phone-m: 25em; //  400px
$bp-phone-wide: 30em; //  480px
$bp-tablet-narrow: 40em; //  640px
$bp-tablet-portrait: 48em; //  768px
$bp-tablet-m: 55em; //  880px
$bp-tablet-landscape: 64em; // 1024px
$bp-laptop-mdpi: 75em; // 1200px
$bp-laptop-hidpi: 90em; // 1440px
$bp-laptop-16: 112em; // 1792px

$viewport-syb-min-width: $bp-tablet-m;
$viewport-syb-min-height: 700px;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.wrapper {
  display: table;
  width: 100%;
  height: 100%;
  margin: auto;

  &.index {
    @media screen and (min-width: $bp-tablet-narrow) {
      display: flex;
      flex-direction: row;
    }
  }
}

.cell {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  position: relative;
}

.page {
  max-width: 30em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.page-part {
  padding: 5vw;

  @media screen and (min-width: $bp-tablet-narrow) {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
  }
}

.article,
.page-part + .page-part {
  background-color: $color-background-paper;
  background-image: url('../img/ricepaper.jpg');
  background-image: image-set(
    url('../img/ricepaper.avif') type('image/avif'),
    url('../img/ricepaper.jpg') type('image/jpeg')
  );

  @media (prefers-color-scheme: dark) {
    background-color: $color-background-paper-dark;
    background-image: url('../img/ricepaper-dark.jpg');
    background-image: image-set(
      url('../img/ricepaper-dark.avif') type('image/avif'),
      url('../img/ricepaper-dark.jpg') type('image/jpeg')
    );
  }

  @media screen and (min-width: $bp-tablet-narrow) {
    justify-content: flex-start;
    align-content: stretch;
  }
}

.article {
  padding: 5em 0;
}

.index {
  main,
  aside {
    display: block;
    width: 100%;
  }

  h1 {
    text-align: center;
  }
}

.chapter {
  height: 90%;

  @media screen and (min-width: $bp-tablet-narrow) {
    max-height: 480px;
  }
  @media screen and (min-width: $viewport-syb-min-width) and (min-height: $viewport-syb-min-height) {
    max-height: 688px;
  }
}

.wrapper.chapter {
  margin-top: 2em;
  padding: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row-reverse wrap;

  @media screen and (min-width: $bp-phone-m) {
    margin-top: 0;
  }

  @media screen and (min-width: $bp-tablet-narrow) {
    flex-wrap: nowrap;
  }

  .title {
    max-width: 30em;
    display: inline-block;
    vertical-align: middle;

    + div {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

/* stylelint-disable-next-line */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .article,
  .page-part + .page-part {
    background-size: 242px 242px;
  }
}

.chapter + .article .cell::before {
  content: '';
  width: 100%;
  height: 1em;
  display: block;
  position: absolute;
  top: -5em;
  background-image: linear-gradient(
      135deg,
      $color-background 25%,
      transparent 25%
    ),
    linear-gradient(225deg, $color-background 25%, transparent 25%);
  background-position: 50%;
  background-size: 1em 100%;

  @media (prefers-color-scheme: dark) {
    background-image: linear-gradient(
        135deg,
        $color-background-dark 25%,
        transparent 25%
      ),
      linear-gradient(225deg, $color-background-dark 25%, transparent 25%);
  }
}
