/* stylelint-disable declaration-property-value-allowed-list */

/* lioshi Theme */
/* Original theme - https://github.com/lioshi/vscode-lioshi-theme */

/* Comment */
.hljs-comment {
  color: #8d8d8d;
}

/* quote */
.hljs-quote {
  color: #b3c7d8;
}

/* Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: #cc6666;
}

/* Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-subst .hljs-link {
  color: #de935f;
}

/* Yellow */
.hljs-attribute {
  color: #f0c674;
}

/* Green */
.hljs-string,
.hljs-bullet,
.hljs-params,
.hljs-addition {
  color: #b5bd68;
}

/* Blue */
.hljs-title,
.hljs-meta,
.hljs-section {
  color: #81a2be;
}

/* Purple */
.hljs-selector-tag,
.hljs-keyword,
.hljs-function,
.hljs-class {
  color: #be94bb;
}

/* Purple light */
.hljs-symbol {
  color: #dbc4d9;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #1a1a1a;
  color: #c5c8c6;
  padding: 0.75em 1em;
  border-radius: 2px;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
